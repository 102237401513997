export default {
  name: 'TirePatternDepthContainer',
  props: {
    depth: {
      type: Object,
      required: true,
    },
    startDepth: {
      type: Number,
      default: 8,
    },
    minimumDepth: {
      type: Number,
      default: 1.6, // Danish law
    },
    recommendedMimimumDepth: {
      // When is it recommended to change tires
      type: Number,
      default: 3, // FDM
    },
  },
  data() {
    return {};
  },
  computed: {
    wear() {
      // Percentage from miminum depth
      const wear = {};

      if (this.depth.front) {
        wear.front = this.getWear(this.depth.front);
      }
      if (this.depth.rear) {
        wear.rear = this.getWear(this.depth.rear);
      }
      return wear;
    },
    level() {
      return Math.max(
        this.wear.front && this.wear.front.level,
        this.wear.rear && this.wear.rear.level,
      );
    },
  },
  methods: {
    getLevel(depth) {
      if (depth <= this.minimumDepth) {
        return 4;
      } else if (depth <= this.recommendedMimimumDepth) {
        return 3;
      } else if (depth <= this.recommendedMimimumDepth + 1) {
        return 2;
      }
      return 1;
    },
    getWear(depth) {
      return {
        level: this.getLevel(depth),
        minimum: (depth - this.minimumDepth) / (this.startDepth - this.minimumDepth),
        recommended:
          (depth - this.recommendedMimimumDepth) / (this.startDepth - this.recommendedMimimumDepth),
      };
    },
  },

  render() {
    return this.$scopedSlots.default({
      wear: this.wear,
      wearLevel: this.level,
      depth: this.depth,
    });
  },
};
