<template>
  <TirePatternDepthContainer v-slot="{ depth, wear, wearLevel }" :depth="data.tire.pattern_depth">
    <DetailsCard :details="details" class="WheelCard">
      <template #header>
        <div>
          <p class="WheelCard__headline">
            {{ data.tire.brand }}
            {{ data.tire.measurement }}
          </p>
          <p class="ft-p2">{{ $t('n_pcs', { n: data.quantity }) }}</p>
        </div>

        <TireSeasonLabel
          class="WheelCard__season"
          :text="data.tire.type"
          :type="data.tire.type_id"
        />
      </template>
      <template #default>
        <Alert hideIcon v-if="data.status !== 4" :level="AlertLevel.INFO">{{
          data.status_text
        }}</Alert>

        <template v-else>
          <Alert v-if="wearLevel > 3" :level="AlertLevel.WARNING">{{
            $t('wheel_storage.message.wear_level_4')
          }}</Alert>
          <Alert v-else-if="wearLevel > 2" :level="AlertLevel.WARNING">{{
            $t('wheel_storage.message.wear_level_3')
          }}</Alert>
          <Alert hideIcon v-if="isExpired" :level="AlertLevel.ERROR">{{
            $t('wheel_storage.message.expiration_date_exceeded')
          }}</Alert>
        </template>
      </template>
      <template #controls v-if="accessible && !noControls">
        <RenewButton :data="data" v-if="isExpired" />
        <Button type="cta" :size="ButtonSizes.SMALL" tag="a" :href="newTiresLink">{{
          $t('wheel_storage.controls.find_new_tires')
        }}</Button>
        <BookingButton :data="data" />
      </template>
    </DetailsCard>
  </TirePatternDepthContainer>
</template>

<script>
import DetailsCard, { Formats } from '@scenes/SelfserviceScene/components/DetailsCard';

import { Button, sizes as ButtonSizes } from '@components/Buttons';
import Alert, { AlertLevel } from '@components/Alert';

import Time from '@components/Time';
import TireSeasonLabel from '../WheelCard/components/TireSeasonLabel';
import TirePatternDepthContainer from '../WheelCard/containers/TirePatternDepth';

import BookingButton from '../WheelCard/components/BookingButton';
import RenewButton from '../WheelCard/components/RenewButton';

import TextWrapper from './components/TextWrapper';
import { StatusLabel, types as StatusLevel } from '@scenes/SelfserviceScene/components/StatusLabel';

import { isBeforeNow } from '@helpers/date';

/*
 Status:
 0: Oprettet
 1: Sendes til CENTRAL
 2: På vej til CENTRAL
 3: Modtaget i CENTRAL
 4: Indlagret
 5: Sendes til FILIAL
 6: På vej til FILIAL
 7: Modtaget i FILIAL
 8: Udleveret til kunden
 9: Skrottet
*/

export default {
  name: 'WheelCard2',

  components: {
    Button,
    Time,
    TirePatternDepthContainer,
    Alert,
    BookingButton,
    DetailsCard,
    TireSeasonLabel,
    RenewButton,
    StatusLabel,
  },

  enums: {
    ButtonSizes,
    AlertLevel,
    StatusLevel,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    noControls: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isExpired() {
      return new Date() > new Date(this.data.expiration_date);
    },
    accessible() {
      return this.data.status <= 4;
    },
    depth() {
      return this.data.tire.pattern_depth;
    },
    orderLink() {
      return this.$router.resolve({
        name: this.data.order_token_anonymous ? '/link/order/:id' : '/selfservice/orders/:id',
        params: { id: this.data.order_token },
      }).href;
    },
    newTiresLink() {
      return `/daek-guide/?sa=${this.data.tire.type_id}&width=${this.data.tire.width}&inches=${this.data.tire.size}&profile=${this.data.tire.profile}`;
    },
    details() {
      return [
        {
          header: this.$t('wheel_storage.storage_id'),
          label: this.data.id,
        },
        {
          header: this.$t('wheel_storage.order_number'),
          label: this.data.order_number,
          format: Formats.LINK,
          href: this.orderLink,
          ellipsis: true,
        },
        {
          header: this.$t('wheel_storage.dropped_off'),
          label: this.data.dropoff_date,
          format: Formats.DATE,
          dateFormat: 'LL',
        },
        {
          header: this.$t('wheel_storage.car'),
          label: this.data.vehicle.description,
          format: Formats.LINK,
          href: this.data.vehicle.url,
          ellipsis: true,
        },
        {
          header: this.$t('wheel_storage.wheel_type'),
          label: this.data.type,
        },
        {
          header: this.$t('wheel_storage.condition'),
          label: this.data.condition,
        },

        {
          header: this.$t('wheel_storage.expire'),
          label: this.data.expiration_date,
          format: Formats.DATE,
          dateFormat: 'LL',
        },
        {
          header: this.$t('wheel_storage.tire_tread'),
          template: TextWrapper,
          templateProps: {
            textParts: [
              `${this.$t('wheel_storage.front_tire')}: ${this.$t('n_mm', { n: this.depth.front })}`,
              `${this.$t('wheel_storage.rear_tire')}: ${this.$t('n_mm', { n: this.depth.rear })}`,
            ],
            hideComma: true,
          },
        },
        {
          header: this.$t('wheel_storage.location'),
          label: this.data.location.name,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.WheelCard {
  .WheelCard__headline {
    font-size: 20px;
    margin-bottom: 0.2em;
    font-weight: normal;
  }
  .WheelCard__subheading {
    font-size: 16px;
    margin-bottom: 0;
  }
  .WheelCard__season {
    font-size: 16px;
  }
}
</style>
