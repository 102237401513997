import { Api } from '../';

export function getWheelStorageBookings() {
  // Test med c:1108982712
  return new Promise((resolve, reject) => {
    Api.get(`/bookings/wheelstorage`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postWheelStorageBooking(wheelStorageToken, pickupDepartment) {
  return new Promise((resolve, reject) => {
    Api.post(`/bookings/wheelstorage`, {
      token: wheelStorageToken,
      pickup_department: pickupDepartment,
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function patchWheelStorageBooking(bookingToken, data) {
  // Test med c:1108982712
  return new Promise((resolve, reject) => {
    Api.patch(`/bookings/wheelstorage/${bookingToken}`, data)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteWheelStorageBooking(bookingToken) {
  return new Promise((resolve, reject) => {
    Api.delete(`/bookings/wheelstorage/${bookingToken}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
