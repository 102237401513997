<template>
  <div class="BookingButton">
    <Button :size="ButtonSizes.SMALL" @click="$refs.modal.open()">{{
      $t('wheel_storage.controls.book')
    }}</Button>
    <Modal ref="modal" :title="$t('wheel_storage.booking_dialog.title')" :working="working">
      <template #default>
        <Alert class="BookingButton__error" v-if="error" :level="AlertLevel.ERROR"
          >{{ $t('error_messages.try_again') }}
        </Alert>
        <p>
          Du er ved at bestille udlevering af:
          <br />
          <strong>{{ tireDescription }}</strong>
        </p>
        <h3 class="BookingButton__headline">Vælg udleveringssted:</h3>
        <StoreFinder
          class="BookingButton__storefinder"
          scrollable
          :onUpdate="setBranch"
          showAlerts
        />
      </template>
      <template #controls="{ close }">
        <Button :color="ButtonColors.WHITE" @click.stop="close">{{ $t('cancel') }}</Button>
        <Button :disabled="branch === null" @click="submit">
          {{ $t('wheel_storage.booking_dialog.submit') }}
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes, colors as ButtonColors } from '@components/Buttons';
import { Modal } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';
import { postWheelStorageBooking } from '@services/thansen/bookings';

import Alert, { AlertLevel } from '@components/Alert';

export default {
  name: 'BookingButton',

  components: {
    Modal,
    StoreFinder,
    Button,
    Alert,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    AlertLevel,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      working: false,
      branch: null,
      error: false,
    };
  },

  computed: {
    tireDescription() {
      return `${this.$tc('n_pcs', this.data.quantity)} ${this.data.tire.brand} ${
        this.data.tire.measurement
      } (${this.data.tire.type.toLowerCase()})`;
    },
  },

  methods: {
    submit() {
      this.working = true;
      postWheelStorageBooking(this.data.token, this.branch.slug)
        .then(response => {
          this.gotoBooking();
        })
        .catch(errors => {
          this.working = false;
          this.error = true;
        });
    },
    setBranch(branch) {
      this.branch = branch;
    },
    gotoBooking() {
      window.location.replace('/booking');
    },
  },
};
</script>

<style lang="scss" scoped>
.BookingButton {
  .BookingButton__storefinder {
    min-height: 300px;
    height: 40vh;
  }

  .BookingButton__headline {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 1em;
  }
  .BookingButton__error {
    margin-bottom: 1em;
  }
}
</style>
