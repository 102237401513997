<template>
  <div class="RenewButton">
    <Button :size="ButtonSizes.SMALL" :color="ButtonColors.WHITE" @click="$refs.modal.open()">
      {{ $t('wheel_storage.controls.renew') }}
    </Button>

    <Modal ref="modal" :title="$t('wheel_storage.renew_dialog.title')" :working="working">
      <template #default>
        <Alert class="RenewButton__error" v-if="error" :level="AlertLevel.ERROR"
          >{{ $t('error_messages.try_again') }}
        </Alert>
        <p>
          Du er ved at bestille fornyelse af dækhotel til: <br />
          <strong>{{ tireDescription }}</strong>
        </p>
      </template>
      <template #controls="{ close }">
        <Button :color="ButtonColors.WHITE" @click.stop="close">{{ $t('cancel') }}</Button>
        <Button @click="submit">{{ $t('wheel_storage.renew_dialog.submit') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes, colors as ButtonColors } from '@components/Buttons';
import { Modal } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';
import { postRenew } from '@services/thansen/wheelstorage';

import Alert, { AlertLevel } from '@components/Alert';

export default {
  name: 'RenewButton',

  components: {
    Modal,
    StoreFinder,
    Button,
    Alert,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    AlertLevel,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      working: false,
      branch: null,
      error: false,
    };
  },

  computed: {
    tireDescription() {
      return `${this.$tc('n_pcs', this.data.quantity)} ${this.data.tire.brand} ${
        this.data.tire.measurement
      } (${this.data.tire.type.toLowerCase()})`;
    },
  },

  methods: {
    submit() {
      this.working = true;
      postRenew(this.data.token)
        .then(() => {
          window.location.href = '/shoppingcart';
        })
        .catch(() => {
          this.working = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.RenewButton {
  .RenewButton__storefinder {
    min-height: 300px;
    height: 40vh;
  }

  .RenewButton__headline {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 1em;
  }
  .RenewButton__error {
    margin-bottom: 1em;
  }
}
</style>
