<template>
  <div class="TireSeasonLabel">
    <i :class="iconClass"></i>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'TireSeasonLabel',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      iconClasses: ['ikon-thghelaars', 'ikon-thgsommer', 'ikon-thgvinter'],
    };
  },
  computed: {
    iconClass() {
      return this.iconClasses[this.type];
    },
  },
};
</script>

<style lang="scss" scoped>
.TireSeasonLabel {
  display: inline-block;
  color: $color-prim-grey-dark;

  text-transform: uppercase;
}
</style>
