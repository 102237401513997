<template>
  <span class="TextWrapper">
    <span v-for="(text, index) in textParts" :key="index" class="TextWrapper__text">
      {{ text + (index !== textParts.length - 1 && !hideComma ? ',' : '') }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'TextWrapper',
  props: {
    textParts: {
      type: Array,
      required: true,
    },
    hideComma: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.TextWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.3em;

  .TextWrapper__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
