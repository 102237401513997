import { Api } from '../';

/**
  https://xapi.thg.dk/wheelstorage/GetById/34837
  https://xapi.thg.dk/wheelstorage/getall
  https://xapi.thg.dk/wheelstorage/BookDelivery
 */

export function getWheels(params) {
  const defaultParams = {};
  return new Promise((resolve, reject) => {
    Api.get(`/wheelstorage`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getWheelByToken(token) {
  const defaultParams = {};
  return new Promise((resolve, reject) => {
    Api.get(`/wheelstorage/${token}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postLookup(storage_id, phone_number) {
  const defaultParams = {};
  return new Promise((resolve, reject) => {
    Api.post(`/wheelstorage/lookup/`, {
      phone_number,
      storage_id,
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postRenew(token) {
  const defaultParams = {};
  return new Promise((resolve, reject) => {
    Api.post(`/wheelstorage/${token}/renew`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postTransfer(data) {
  const defaultParams = {};
  return new Promise((resolve, reject) => {
    Api.post(`/wheelstorage/transfer`, data)
      .then(response => {
        if (response.data.errors) reject(response.data.errors);
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
